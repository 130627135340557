import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { db } from "../../scripts/firebase";

export const getNewImageUrl = async (image, imageID, listingID, listerID) => {
  const storage = getStorage();
  return getDownloadURL(
    ref(
      storage,
      `Properties/${listingID}/Images/${image.temporaryID}` + "_1080x1080"
    )
  )
    .then(async (url) => {
      await updateDoc(
        doc(
          db,
          "Listers",
          listerID,
          "ListerProperties",
          listingID,
          "PropertyImages",
          imageID
        ),
        {
          url,
          updatedFromClient: true,
          updateFromClientAt: serverTimestamp(),
        }
      ).then(async () => {
        // console.log("Doc Added: "+ docRef.id )

        const propRef = doc(
          db,
          "Listers",
          listerID,
          "ListerProperties",
          listingID
        );
        await updateDoc(propRef, {
          featureImageUrl: image.isFeatureImage ? url : null,
          [`images.${imageID}.url`]: url,
          [`images.${imageID}.updatedFromClient`]: true,
          [`images.${imageID}.updateFromClientAt`]: serverTimestamp(),
        });
        return true;
      });
    })
    .catch((error) => {
      // Handle any errors
      console.log(error);
      return false;
    });
};
