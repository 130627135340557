import * as React from "react";
import { useState, useRef, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineBrokenImage,
  MdOutlineVilla,
} from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import Image from "next/image";
import { useRouter } from "next/router";
import { getNewImageUrl } from "../../../../utils/image/getImageUrl";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import * as Sentry from "@sentry/nextjs";
import useEmblaCarousel from "embla-carousel-react";
import { getUrl } from "../../../../utils/getUrl";
import { BiLogoWhatsapp } from "react-icons/bi";
import { useAuth } from "../../../../auth/AuthProvider";
import { capture } from "../../../../utils/analytics/capture";
// import * as Sentry from "@sentry/nextjs";
// const Sentry = dynamic(() => import("@sentry/nextjs"));

let Sentry;

export const Slider = ({
  propertyImages,
  propertyData,
  sendEvent,
  source,
  loadingProperty,
  setLoadingProperty,
  edit,
  type,
  setImagesModalOpen,
  isIntersecting,
  fromMap,
  bottomMapCard,
  propindex,
  count,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // console.log(propertyData);

  const router = useRouter();

  const contextAuth = useAuth();
  const user = contextAuth.user;
  const claims = contextAuth.claims;

  const images = Object.entries(propertyImages ?? {}).sort((a, b) => {
    if (a[1].position > a[1].position) return 1;
    if (a[1].position < b[1].position) return -1;
    return 0;
  });

  const [loading, setLoading] = useState(images.map(() => true));
  const [error, setError] = useState(images.map(() => false));

  // console.log(loading);

  const handleImageLoad = (index) => {
    // Update the loading status of the image at the given index
    setLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
    if (error[index]) {
      setError((prevError) => {
        const newError = [...prevError];
        newError[index] = false;
        return newError;
      });
    }
  };

  const handleImageError = (index) => {
    // Update the error status of the image at the given index
    setError((prevError) => {
      const newError = [...prevError];
      newError[index] = true;
      return newError;
    });
  };

  const [color, setColor] = useState("blue");

  useEffect(() => setColor("red"), []);

  const activeDotRef = useRef(null);
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current && activeDotRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const dotWidth = activeDotRef.current.offsetWidth;
      const scrollLeft =
        activeDotRef.current.offsetLeft - containerWidth / 2 + dotWidth / 2;
      containerRef.current.scrollLeft = scrollLeft;
    }
  }, [currentIndex]);
  const [scrollX, setScrollX] = useState(0);

  const handleScroll = () => {
    const activeDot = document.querySelector(".active");
    if (activeDot) {
      const activeDotLeft = activeDot.offsetLeft;
      const containerWidth = document.querySelector(".dots").clientWidth;
      const scrollX = window.scrollX + activeDotLeft - containerWidth / 2;
      setScrollX(scrollX);
    }
  };

  if (color === "red") {
    window.addEventListener("scroll", handleScroll);
  }

  const titleTwo = `${
    (propertyData.noOfBedrooms === 0
      ? propertyData.propertyType
      : propertyData.noOfBedrooms + " bedroom " + propertyData.propertyType) +
    " for " +
    propertyData.listingType.toLowerCase() +
    " " +
    (propertyData.listingType === "RENT"
      ? `- Kshs ${Intl.NumberFormat().format(propertyData.monthlyRent)}/mo - `
      : `- Kshs ${Intl.NumberFormat().format(propertyData.price)} - `) +
    " in " +
    propertyData.propertyTown.townName +
    " " +
    (propertyData.propertyArea.descriptor
      ? propertyData.propertyArea.descriptor.toLowerCase()
      : " ") +
    " " +
    propertyData.propertyArea.areaName +
    ", " +
    propertyData.propertyCounty.countyName
  }`;

  const propertyID = propertyData.objectID
    ? propertyData.objectID
    : propertyData.propertyID;

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  useEffect(() => {
    if (emblaApi) {
      // console.log(emblaApi.slideNodes()); // Access API
    }
  }, [emblaApi]);
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const scrollToImage = useCallback(
    (index) => {
      if (emblaApi) emblaApi.scrollTo(index, true);
    },
    [emblaApi]
  );

  const onSelect = useCallback(
    (emblaApi, eventName) => {
      if (!emblaApi) return;
      // console.log(`Embla just triggered ${eventName}!`);
      // console.log(emblaApi.selectedScrollSnap());
      // console.log(images[emblaApi.selectedScrollSnap()]);
      // console.log(images.length);

      setCurrentIndex(emblaApi.selectedScrollSnap());
      // if (emblaApi.selectedScrollSnap() === images.length) {
      //   console.log("reached end");
      // }
      capture({
        category: "browse",
        event: "image_view",
        properties: {
          image:
            emblaApi.selectedScrollSnap() === images.length
              ? null
              : images[emblaApi.selectedScrollSnap()][1],
          imageID:
            emblaApi.selectedScrollSnap() === images.length
              ? null
              : images[emblaApi.selectedScrollSnap()][0],
          position: emblaApi.selectedScrollSnap(),
          propertyID,
          property: propertyData,
          imageCount: images.length,
          ctaReach: emblaApi.selectedScrollSnap() === images.length,
          lightbox: false,
        },
      });
    },
    [emblaApi]
  );

  useEffect(() => {
    if (!emblaApi) return;
    // onSelect();
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  // Define the property details
  const propertyChat = {
    phone: `+${process.env.NEXT_PUBLIC_WA_SOURCE_NUMBER}`,
    reference: propertyID,
    type: propertyData.propertyType,
    price:
      propertyData.listingType === "RENT"
        ? Intl.NumberFormat().format(propertyData.monthlyRent)
        : Intl.NumberFormat().format(propertyData.price),
    location: propertyData.propertyTown.townName,
    link: `${getUrl()}/homes/${propertyData.slugShort}`,
  };

  //  whatsapp vars
  const listerName = propertyData.listerName;

  let message;

  if (listerName === "Ula") {
    message = `Hello, I would like to get more information about this house

Reference: ${propertyChat.reference}
Type: ${propertyChat.type}
Price: Kshs ${
      propertyChat.price + (propertyData.listingType === "RENT" ? "/mo" : "")
    }
Town: ${propertyChat.location}
Property: ${propertyChat.link}
  
Any changes made to this message will result in the enquiry not being sent to your assigned agent.`;
  } else {
    message = `Hello, I would like to get more information about this house you posted on ula.africa

Reference: ${propertyChat.reference}
Type: ${propertyChat.type}
Price: Kshs ${
      propertyChat.price + (propertyData.listingType === "RENT" ? "/mo" : "")
    }
Town: ${propertyChat.location}
Property: ${propertyChat.link}
  
Any changes made to this message will result in the enquiry not being sent to the agent.`;
  }

  // URL encode the message
  const encodedMessage = encodeURIComponent(message);

  // Create the WhatsApp Click to Chat URL
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${propertyChat.phone}&text=${encodedMessage}`;

  //

  return (
    <>
      <div
        className={
          " group relative flex h-full w-full   overflow-hidden   " +
          (source === "MAIN-PROPERTY" || bottomMapCard
            ? " rounded-t-none"
            : " rounded-t-md")
        }
      >
        <div className="embla relative flex h-full  w-full  items-center">
          <div
            className="embla__viewport  flex h-full w-full flex-row overflow-hidden  bg-gray-200"
            ref={emblaRef}
          >
            <div className="embla__container flex h-full w-full flex-row ">
              {images.map((el, index) => {
                // console.log(isIntersecting + " " + color);
                // console.log(
                //   (isIntersecting &&
                //     (index === currentIndex || index === currentIndex + 1)) +
                //     " " +
                //     index.toString() +
                //     " unoptimized" +
                //     (index > 0).toString()
                // );
                // console.log((index === 0 && propindex < 4) + " " + propindex);
                return (
                  <div
                    key={el[0]}
                    className="embla__slide relative  h-full w-full min-w-0  flex-shrink-0 flex-row overflow-x-hidden"
                  >
                    {loading[index] && !error[index] && (
                      <div className=" absolute flex h-full w-full items-center justify-center">
                        <MdOutlineVilla className=" h-10 w-10 animate-pulse text-gray-500" />
                      </div>
                    )}
                    {error[index] && (
                      <div className=" absolute flex  h-full w-full flex-col items-center justify-center">
                        <MdOutlineBrokenImage className=" h-10 w-10  text-gray-500" />
                        <span className=" text-sm text-gray-500">
                          Error loading image
                        </span>
                      </div>
                    )}
                    {loadingProperty === propertyID ? (
                      <AnimatePresence>
                        <div className=" absolute z-10 flex h-full w-full items-center  justify-center bg-black opacity-50  ">
                          <AiOutlineLoading3Quarters className="   z-10 h-5 w-5 animate-spin text-white" />
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 0.4 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="absolute  h-full w-full  bg-black"
                          ></motion.div>
                        </div>
                      </AnimatePresence>
                    ) : null}
                    <Image
                      alt={
                        !error[index]
                          ? titleTwo +
                            (index === 0
                              ? " - main property image"
                              : " - property image " + (index + 1))
                          : ""
                      }
                      // unoptimized={index > 0}
                      unoptimized={true}
                      key={index}
                      priority={
                        (index === 0 && propindex < 4) ||
                        (isIntersecting &&
                          (index === currentIndex ||
                            index === currentIndex + 1))
                      }
                      onClick={() => {
                        if (fromMap && !bottomMapCard) {
                          window.open(
                            `${getUrl()}/homes/${propertyData.slugShort}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                          return;
                        }
                        if (source != "DASH") {
                          // main property image or normal hit images
                          if (source === "MAIN-PROPERTY") {
                            setImagesModalOpen(true);
                            return;
                          }
                          setLoadingProperty(propertyID);
                          if (sendEvent) {
                            sendEvent(
                              "click",
                              propertyData,
                              "property_clicked"
                            );
                          }
                          router
                            .push(`/homes/${propertyData.slugShort}`)
                            .then(() => {
                              setLoadingProperty(null);
                            });
                        } else {
                          // from dashboard
                          // handle dashboard logic
                          if (
                            propertyData.status &&
                            (type === "rent" || type === "sale")
                          ) {
                            setLoadingProperty(propertyData.propertyID);
                            router
                              .push({
                                pathname: "/lister/listing/[listing]",
                                query: {
                                  listing: propertyData.propertyID,
                                  type: type,
                                  edit: edit,
                                },
                              })
                              .then(() => {
                                setLoadingProperty(null);
                              });
                          }
                        }
                      }}
                      src={el[1].url}
                      layout="fill"
                      // width={500}
                      // height={260}
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                      className={
                        "   object-cover  duration-300 lg:hover:scale-105 " +
                        (error[index] ? " -z-10 hidden" : "")
                      }
                      onLoad={async () => {
                        //console.log("loaded" + index);
                        handleImageLoad(index);
                      }}
                      onError={async () => {
                        const err = new Error(
                          `Failed to load image: ${el[0]} for Property: ${propertyID}`
                        );
                        if (
                          user &&
                          user.uid === propertyData.inputData.addedByUID
                        ) {
                          await getNewImageUrl(
                            el[1],
                            el[0],
                            propertyData.propertyID,
                            user?.uid
                          );
                        } else {
                          // console.log(Sentry);
                          if (!Sentry) {
                            import("@sentry/nextjs").then((sentry) => {
                              // console.log("loaded");
                              Sentry = sentry;
                              Sentry.captureException(err);
                            });
                          } else {
                            Sentry.captureException(err);
                          }
                        }
                        handleImageError(index);
                      }}
                    />
                  </div>
                );
              })}
              {propertyData.market === "IN-MARKET" ? (
                <div className=" flex h-full w-full flex-shrink-0 flex-col items-center justify-center space-y-2 bg-[#0D242A]">
                  <span className=" text-center text-sm font-semibold text-gray-300">
                    Like what you see? Places go fast.<br></br> Contact today!
                  </span>
                  <a
                    onClick={(e) => {
                      if (user && claims.manager) {
                        e.preventDefault();

                        return;
                      }
                      capture({
                        category: "lead",
                        event: "whatsapp_click",
                        properties: propertyData,
                      });
                    }}
                    href={whatsappUrl}
                    rel="nofollow noreferrer"
                    className=" flex flex-row items-center space-x-1 rounded-md bg-[#23d366] px-6 py-2 text-sm font-semibold"
                  >
                    <BiLogoWhatsapp className=" h-6 w-6 text-gray-100 " />{" "}
                    <span className=" text-gray-100">Whatsapp</span>
                  </a>
                </div>
              ) : null}
            </div>
          </div>

          {emblaApi?.canScrollPrev() ? (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                scrollPrev();
              }}
              aria-label="Go to the previous property image"
              className="absolute left-2 -z-10  mb-3 cursor-pointer items-center justify-center  rounded-full  bg-gray-200 p-2 hover:bg-white  hover:opacity-100 group-hover:flex group-hover:opacity-90 group-hover:transition group-hover:duration-300 lg:z-10 lg:bg-gray-300 lg:opacity-0"
            >
              <MdOutlineArrowBackIos className=" " />
            </a>
          ) : null}
          {emblaApi?.canScrollNext() ? (
            <a
              href=""
              aria-label="Go to the next property image"
              onClick={(e) => {
                e.preventDefault();
                scrollNext();
              }}
              className="absolute right-2 -z-10  mb-3 cursor-pointer items-center justify-center  rounded-full  bg-gray-300 p-2 hover:bg-white  hover:opacity-100 group-hover:flex group-hover:opacity-90 group-hover:transition group-hover:duration-300 lg:z-10 lg:bg-gray-300 lg:opacity-0"
            >
              <MdOutlineArrowForwardIos className="" />
            </a>
          ) : null}
          <div
            className={
              " absolute bottom-0 flex h-10  w-full flex-row items-center justify-center rounded-b-md" +
              (bottomMapCard ? " hidden" : "")
            }
          >
            <div
              ref={containerRef}
              className=" dots flex w-32 flex-row items-center justify-center overflow-hidden  "
            >
              {images.map((_, index) => {
                const size =
                  index === currentIndex
                    ? "h-4 w-4 "
                    : index === 0 || index === images.length - 1
                    ? "h-3 w-3"
                    : "h-3 w-3";
                const left = (index * 10).toString();
                return (
                  <GoDotFill
                    onClick={() => scrollToImage(index)}
                    key={index}
                    style={{
                      left: left,
                    }}
                    className={
                      " flex  flex-shrink-0 cursor-pointer text-gray-200 " +
                      `${size} `
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
